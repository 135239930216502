// src/components/AdminDayUseUser.js
import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { 
  Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, CircularProgress, Box 
} from '@mui/material';

const AdminDayUseUser = () => {
  const [dailyActiveUsers, setDailyActiveUsers] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDailyActiveUsers = async () => {
      setLoading(true);
      const usersCollection = collection(db, "Users");

      try {
        const querySnapshot = await getDocs(usersCollection);
        const counts = {};

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const loginDates = data.loginDate;

          // Skip if loginDate is missing or not an array
          if (Array.isArray(loginDates)) {
            loginDates.forEach((date) => {
              if (date) {
                counts[date] = (counts[date] || 0) + 1;
              }
            });
          }
        });

        setDailyActiveUsers(counts);
      } catch (error) {
        console.error("Error fetching daily active users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDailyActiveUsers();
  }, []);

  const renderTableRows = () => {
    const rows = Object.entries(dailyActiveUsers).map(([date, count]) => ({
      date,
      count,
    }));

    // Sort dates in descending order (most recent date first)
    rows.sort((a, b) => new Date(b.date) - new Date(a.date));

    return rows.map((row, index) => (
      <TableRow key={index}>
        <TableCell>{row.date}</TableCell>
        <TableCell>{row.count}</TableCell>
      </TableRow>
    ));
  };

  return (
    <TableContainer component={Paper}>
      {loading ? (
        <Box mt={2} mb={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Daily Active Users</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default AdminDayUseUser;
