// src/components/AdminDayUserAdd.js
import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { 
  Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, CircularProgress, Box 
} from '@mui/material';

const AdminDayUserAdd = () => {
  const [dailyUserCounts, setDailyUserCounts] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDailyUserCounts = async () => {
      setLoading(true);
      const usersCollection = collection(db, "Users");

      try {
        const querySnapshot = await getDocs(usersCollection);
        const counts = {};

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const createAt = data.createAt;

          // Skip if createAt is missing or invalid
          if (createAt && typeof createAt.toDate === 'function') {
            const date = createAt.toDate();

            // Convert to Taiwan timezone
            const options = { timeZone: 'Asia/Taipei', year: 'numeric', month: '2-digit', day: '2-digit' };
            const dateInTaipei = new Intl.DateTimeFormat('zh-TW', options).format(date);
            const dateKey = dateInTaipei.replace(/\//g, '-'); // Format: YYYY-MM-DD in Taiwan timezone

            counts[dateKey] = (counts[dateKey] || 0) + 1;
          }
        });

        setDailyUserCounts(counts);
      } catch (error) {
        console.error("Error fetching daily user counts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDailyUserCounts();
  }, []);

  const renderTableRows = () => {
    const rows = Object.entries(dailyUserCounts).map(([date, count]) => ({
      date,
      count,
    }));

    // Sort dates in descending order (most recent date first)
    rows.sort((a, b) => new Date(b.date) - new Date(a.date));

    return rows.map((row, index) => (
      <TableRow key={index}>
        <TableCell>{row.date}</TableCell>
        <TableCell>{row.count}</TableCell>
      </TableRow>
    ));
  };

  return (
    <TableContainer component={Paper}>
      {loading ? (
        <Box mt={2} mb={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date (Taiwan Time)</TableCell>
              <TableCell>User Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default AdminDayUserAdd;
