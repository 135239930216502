// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';        


// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyC8LJ2HGIbXGktTlDaE7Orqtk16zzFaoSo",
  authDomain: "daily-eat.com",
  databaseURL: "https://dailyeat-a18ec-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "dailyeat-a18ec",
  storageBucket: "dailyeat-a18ec.appspot.com",
  messagingSenderId: "225392344693",
  appId: "1:225392344693:web:95c66471d988151a0e423c",
  measurementId: "G-TYX7J44JFD"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);


export { db, auth };
