import React from 'react';
import logo from '../assets/images/logo-1024.png';
import screen1 from '../assets/images/screen1.png';
import screen2 from '../assets/images/screen2.png';
import screen3 from '../assets/images/screen3.png';
import screen4 from '../assets/images/screen4.png';
import screen5 from '../assets/images/screen5.png';
import screen6 from '../assets/images/screen6.png';
import i18n from '../i18n/i18n';

function Home() {
  return (
    <div className="flex flex-col items-center bg-gray-900 text-white min-h-screen py-8 px-4">
      <header className="flex flex-col items-center my-8 w-full">
        <div className="flex flex-wrap items-center justify-center w-full">
          <img src={logo} className="h-28 w-28 rounded-2xl mb-4 mx-4" alt="logo" />
          <h1 className="text-5xl font-bold mx-4">DailyEat</h1>
        </div>
        <h2 className="text-3xl font-bold mt-10 text-center">{i18n.t("AI自動分析熱量和三大主要營養素")}</h2>
        <p className="text-lg mt-6 font-bold text-center text-gray-400">{i18n.t("一張照片或影片即可分析食物的熱量和三大主要營養素")}</p>
      </header>
      <div className="flex flex-wrap justify-center gap-4 my-8">
        <img src={screen1} alt="Screenshot 1" className="w-48 rounded-2xl" />
        <img src={screen2} alt="Screenshot 2" className="w-48 rounded-2xl" />
        <img src={screen3} alt="Screenshot 3" className="w-48 rounded-2xl" />
        <img src={screen4} alt="Screenshot 4" className="w-48 rounded-2xl" />
        <img src={screen5} alt="Screenshot 5" className="w-48 rounded-2xl" />
        <img src={screen6} alt="Screenshot 6" className="w-48 rounded-2xl" />
      </div>
      <footer className="mt-10 mb-4">
        <p className="text-lg flex items-center text-gray-400">
          <a href="mailto:2024dailyeat@gmail.com" className="flex items-center text-gray-400">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="currentColor">
              <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
            </svg>
            2024dailyeat@gmail.com
          </a>
        </p>
      </footer>
    </div>
  );
}

export default Home;
