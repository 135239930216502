import React, { useEffect, useState, useRef } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs, query, orderBy, limit, startAfter } from "firebase/firestore";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress, Box } from '@mui/material';

const AdminPosts = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const tableRef = useRef(null);

  const columnWidths = {
    createdAt: '90px',
    userId: '130px',
    description: '150px',
    foodData: '150px',
    video: '100px',
    image: '100px',
    id: '150px'
  };

  const fetchData = async (nextPage = false) => {
    setLoading(true);
    const collectionRef = collection(db, "Posts");
    const timestampField = "createdAt";

    let q;
    if (nextPage && lastVisible) {
      q = query(collectionRef, orderBy(timestampField, "desc"), startAfter(lastVisible), limit(50));
    } else {
      q = query(collectionRef, orderBy(timestampField, "desc"), limit(50));
    }

    const querySnapshot = await getDocs(q);
    const dataList = querySnapshot.docs.map(doc => doc.data());
    const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

    if (nextPage) {
      setData(prevData => [...prevData, ...dataList]);
    } else {
      setData(dataList);
    }

    setLastVisible(lastVisibleDoc);
    setHasNextPage(querySnapshot.docs.length === 50);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleRowExpansion = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index]
    }));
  };
  
  const renderTableHeaders = () => {
    const headers = ["createdAt", "userId", "description", "foodData", "video", "image", "id"];
    return (
      <TableRow>
        {headers.map((header) => (
          <TableCell key={header} style={{ maxWidth: columnWidths[header] || '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {header}
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    );
  };

  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return "null";
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    return date.toLocaleString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(/\//g, '-').replace(',', '');
  };

  const renderMediaContent = (content, type) => {
    if (!content) return "null";
    switch (type) {
      case 'video':
        return (
          <video width="100%" height="auto" controls>
            <source src={content} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      case 'image':
      case 'thumbnail':
        return (
          <img src={content} alt={type} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        );
      default:
        return JSON.stringify(content, null, 2);
    }
  };

  const calculateNutrition = (foodData) => {
    if (!foodData) return { name: "", totalP: 0, totalC: 0, totalF: 0, totalCalories: 0, ingredients: [] };
    const ingredients = foodData.ingredients || [];
    let totalP = 0, totalC = 0, totalF = 0;
  
    const ingredientDetails = ingredients.map(ingredient => {
      const p = ingredient.nutrients.p || 0;
      const c = ingredient.nutrients.c || 0;
      const f = ingredient.nutrients.f || 0;
      const calories = p * 4 + c * 4 + f * 9;
      totalP += p;
      totalC += c;
      totalF += f;
      return {
        name: ingredient.name || "",
        calories: calories.toFixed(1),
        p: p.toFixed(1),
        c: c.toFixed(1),
        f: f.toFixed(1)
      };
    });
  
    const totalCalories = totalP * 4 + totalC * 4 + totalF * 9;
    return { 
      name: foodData.name || "", 
      totalP: totalP.toFixed(1), 
      totalC: totalC.toFixed(1), 
      totalF: totalF.toFixed(1), 
      totalCalories: totalCalories.toFixed(1),
      ingredients: ingredientDetails
    };
  };

  const renderTableRows = () => {
    const fields = ["createdAt", "userId", "description", "foodData", "video", "image", "id"];
    return data.map((item, index) => {
      const isExpanded = expandedRows[index];
      const nutrition = item.foodData ? calculateNutrition(item.foodData) : null;
  
      return (
        <TableRow key={index} style={{ height: isExpanded ? 'auto' : '72px' }}>
          {fields.map((field) => {
            let value;
            if (field === 'foodData') {
              value = (
                <div>
                  <div><strong>{nutrition.name}</strong></div>
                  <div>熱量: {nutrition.totalCalories}</div>
                  <div>碳水: {nutrition.totalC}</div>
                  <div>蛋白質: {nutrition.totalP}</div>
                  <div>脂肪: {nutrition.totalF}</div>
                  {isExpanded && (
                    <div style={{marginTop: '10px'}}>
                      <strong>成分:</strong>
                      {nutrition.ingredients.map((ingredient, idx) => (
                        <div key={idx} style={{marginTop: '5px'}}>
                          <div><strong>{ingredient.name}</strong></div>
                          <div>熱量: {ingredient.calories}</div>
                          <div>碳水: {ingredient.c}</div>
                          <div>蛋白質: {ingredient.p}</div>
                          <div>脂肪: {ingredient.f}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            } else if (field === 'video' || field === 'image' || field === 'thumbnail') {
              value = renderMediaContent(item[field], field);
            } else {
              value = (item[field] !== undefined && item[field] !== "" ? JSON.stringify(item[field], null, 2).replace(/^"|"$/g, '') : "null");
            }
  
            return (
              <TableCell 
                key={field} 
                style={{ 
                  maxWidth: isExpanded ? 'none' : (columnWidths[field] || '150px'), 
                  whiteSpace: isExpanded ? 'normal' : 'pre-wrap', 
                  wordWrap: 'break-word', 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis' 
                }}
              >
                <div style={{ overflow: 'hidden', maxHeight: !isExpanded && field === 'stack' ? '72px' : 'none', textOverflow: 'ellipsis' }}>
                  {field.includes('createdAt')
                    ? formatDate(item[field])
                    : value}
                </div>
              </TableCell>
            );
          })}
          <TableCell>
            <Button onClick={() => toggleRowExpansion(index)}>
              {isExpanded ? 'Collapse' : 'Expand'}
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  };

  const handleNextPage = () => {
    const currentScrollPosition = tableRef.current.scrollTop;
    fetchData(true).then(() => {
      if (tableRef.current) {
        tableRef.current.scrollTop = currentScrollPosition;
      }
    });
  };

  return (
    <TableContainer component={Paper} ref={tableRef} style={{ overflow: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          {renderTableHeaders()}
        </TableHead>
        <TableBody>
          {renderTableRows()}
        </TableBody>
      </Table>
      {hasNextPage && (
        <Box mt={2} mb={2} display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleNextPage} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Load More"}
          </Button>
        </Box>
      )}
    </TableContainer>
  );
};

export default AdminPosts;