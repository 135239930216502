// src/components/AdminLanUsers.js
import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { 
  Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, CircularProgress, Box 
} from '@mui/material';

const AdminLanUsers = () => {
  const [languageCounts, setLanguageCounts] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLanguageCounts = async () => {
      setLoading(true);
      const usersCollection = collection(db, "Users");

      try {
        const querySnapshot = await getDocs(usersCollection);
        const counts = {};
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const language = data.deviceLan || 'Unknown';
          counts[language] = (counts[language] || 0) + 1;
        });
        setLanguageCounts(counts);
      } catch (error) {
        console.error("Error fetching language counts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLanguageCounts();
  }, []);

  const renderTableRows = () => {
    const rows = Object.entries(languageCounts).map(([language, count]) => ({
      language,
      count,
    }));

    // Sort languages by user count in descending order
    rows.sort((a, b) => b.count - a.count);

    return rows.map((row, index) => (
      <TableRow key={index}>
        <TableCell>{row.language}</TableCell>
        <TableCell>{row.count}</TableCell>
      </TableRow>
    ));
  };

  return (
    <TableContainer component={Paper}>
      {loading ? (
        <Box mt={2} mb={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Language</TableCell>
              <TableCell>User Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default AdminLanUsers;
