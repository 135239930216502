// import { getLocales } from 'expo-localization';
import { I18n } from 'i18n-js';
// import { Platform } from 'react-native';
import translations from './translations';

// 將翻譯內容設置在這裡
const i18n = new I18n(translations);



// 根據瀏覽器語言設置當前語言
const userLang = navigator.languages && navigator.languages.length
  ? navigator.languages[0]
  : navigator.language;

let deviceLan = userLang.split('-')[0]; // 獲取語言代碼
let regionCode = userLang.split('-')[1] || ''; // 獲取地區代碼

if (deviceLan === 'zh') {
  if (userLang === 'zh-CN') {
    deviceLan = 'zh-Hans';
  } else {
    deviceLan = 'zh-Hant';
  }
}

if (!translations.hasOwnProperty(deviceLan)) {
  deviceLan = 'en';
}

i18n.locale = deviceLan;
i18n.region = regionCode;
i18n.fallbacks = true;

export default i18n;
