// src/components/AdminSuggestions.js
import React, { useEffect, useState, useRef } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs, query, orderBy, limit, startAfter } from "firebase/firestore";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress, Box } from '@mui/material';

const AdminSuggestions = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const tableRef = useRef(null);

  const columnWidths = {
    createAt: '100px',
    os: '100px',
    suggestion: '300px',
    userEmail: '200px',
    userId: '130px'
  };

  const fetchData = async (nextPage = false) => {
    setLoading(true);
    const collectionRef = collection(db, "Suggestions");
    const timestampField = "createAt";

    let q;
    if (nextPage && lastVisible) {
      q = query(collectionRef, orderBy(timestampField, "desc"), startAfter(lastVisible), limit(50));
    } else {
      q = query(collectionRef, orderBy(timestampField, "desc"), limit(50));
    }

    const querySnapshot = await getDocs(q);
    const dataList = querySnapshot.docs.map(doc => doc.data());
    const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

    if (nextPage) {
      setData(prevData => [...prevData, ...dataList]);
    } else {
      setData(dataList);
    }

    setLastVisible(lastVisibleDoc);
    setHasNextPage(querySnapshot.docs.length === 50);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleRowExpansion = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const renderTableHeaders = () => {
    const headers = ["createAt", "os", "suggestion", "userEmail", "userId"];
    return (
      <TableRow>
        {headers.map((header) => (
          <TableCell key={header} style={{ maxWidth: columnWidths[header] || '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {header}
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    );
  };

  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return "null";
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    return date.toLocaleString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(/\//g, '-').replace(',', '');
  };

  const renderTableRows = () => {
    const fields = ["createAt", "os", "suggestion", "userEmail", "userId"];
    return data.map((item, index) => {
      const isExpanded = expandedRows[index];

      return (
        <TableRow key={index} style={{ height: isExpanded ? 'auto' : '72px' }}>
          {fields.map((field) => {
            let value = (item[field] !== undefined && item[field] !== "" ? JSON.stringify(item[field], null, 2).replace(/^"|"$/g, '') : "null");

            if (field === 'suggestion') {
              value = value.split('\\n').map((str, idx) => (
                <span key={idx}>
                  {str}
                  <br />
                </span>
              ));
            }

            return (
              <TableCell
                key={field}
                style={{
                  maxWidth: isExpanded ? 'none' : (columnWidths[field] || '150px'),
                  whiteSpace: isExpanded ? 'normal' : 'pre-wrap',
                  wordWrap: 'break-word',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                <div style={{ overflow: 'hidden', maxHeight: !isExpanded && field === 'suggestion' ? '72px' : 'none', textOverflow: 'ellipsis' }}>
                  {field.includes('createAt')
                    ? formatDate(item[field])
                    : value}
                </div>
              </TableCell>
            );
          })}
          <TableCell>
            <Button onClick={() => toggleRowExpansion(index)}>
              {isExpanded ? 'Collapse' : 'Expand'}
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  };

  const handleNextPage = () => {
    const currentScrollPosition = tableRef.current.scrollTop;
    fetchData(true).then(() => {
      if (tableRef.current) {
        tableRef.current.scrollTop = currentScrollPosition;
      }
    });
  };

  return (
    <TableContainer component={Paper} ref={tableRef} style={{ overflow: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          {renderTableHeaders()}
        </TableHead>
        <TableBody>
          {renderTableRows()}
        </TableBody>
      </Table>
      {hasNextPage && (
        <Box mt={2} mb={2} display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleNextPage} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Load More"}
          </Button>
        </Box>
      )}
    </TableContainer>
  );
};

export default AdminSuggestions;
