import ab from './locales/ab.json';
import aa from './locales/aa.json';
import af from './locales/af.json';
import ak from './locales/ak.json';
import sq from './locales/sq.json';
import am from './locales/am.json';
import ar from './locales/ar.json';
import an from './locales/an.json';
import hy from './locales/hy.json';
import as from './locales/as.json';
import av from './locales/av.json';
import ae from './locales/ae.json';
import ay from './locales/ay.json';
import az from './locales/az.json';
import bm from './locales/bm.json';
import ba from './locales/ba.json';
import eu from './locales/eu.json';
import be from './locales/be.json';
import bn from './locales/bn.json';
import bh from './locales/bh.json';
import bi from './locales/bi.json';
import bs from './locales/bs.json';
import br from './locales/br.json';
import bg from './locales/bg.json';
import my from './locales/my.json';
import ca from './locales/ca.json';
import ch from './locales/ch.json';
import ce from './locales/ce.json';
import ny from './locales/ny.json';
import zh from './locales/zh.json';
import zhHans from './locales/zh-Hans.json';
import zhHant from './locales/zh-Hant.json';
import cv from './locales/cv.json';
import kw from './locales/kw.json';
import co from './locales/co.json';
import cr from './locales/cr.json';
import hr from './locales/hr.json';
import cs from './locales/cs.json';
import da from './locales/da.json';
import dv from './locales/dv.json';
import nl from './locales/nl.json';
import dz from './locales/dz.json';
import en from './locales/en.json';
import eo from './locales/eo.json';
import et from './locales/et.json';
import ee from './locales/ee.json';
import fo from './locales/fo.json';
import fj from './locales/fj.json';
import fi from './locales/fi.json';
import fr from './locales/fr.json';
import ff from './locales/ff.json';
import gl from './locales/gl.json';
import gd from './locales/gd.json';
import gv from './locales/gv.json';
import ka from './locales/ka.json';
import de from './locales/de.json';
import el from './locales/el.json';
import kl from './locales/kl.json';
import gn from './locales/gn.json';
import gu from './locales/gu.json';
import ht from './locales/ht.json';
import ha from './locales/ha.json';
import he from './locales/he.json';
import hz from './locales/hz.json';
import hi from './locales/hi.json';
import ho from './locales/ho.json';
import hu from './locales/hu.json';
import is from './locales/is.json';
import io from './locales/io.json';
import ig from './locales/ig.json';
import id from './locales/id.json';
import ia from './locales/ia.json';
import ie from './locales/ie.json';
import iu from './locales/iu.json';
import ga from './locales/ga.json';
import it from './locales/it.json';
import ja from './locales/ja.json';
import jv from './locales/jv.json';
import kn from './locales/kn.json';
import kr from './locales/kr.json';
import ks from './locales/ks.json';
import kk from './locales/kk.json';
import km from './locales/km.json';
import ki from './locales/ki.json';
import rw from './locales/rw.json';
import rn from './locales/rn.json';
import ky from './locales/ky.json';
import kv from './locales/kv.json';
import kg from './locales/kg.json';
import ko from './locales/ko.json';
import ku from './locales/ku.json';
import kj from './locales/kj.json';
import lo from './locales/lo.json';
import la from './locales/la.json';
import lv from './locales/lv.json';
import li from './locales/li.json';
import ln from './locales/ln.json';
import lt from './locales/lt.json';
import lu from './locales/lu.json';
import lg from './locales/lg.json';
import lb from './locales/lb.json';
import mk from './locales/mk.json';
import mg from './locales/mg.json';
import ms from './locales/ms.json';
import ml from './locales/ml.json';
import mt from './locales/mt.json';
import mi from './locales/mi.json';
import mr from './locales/mr.json';
import mh from './locales/mh.json';
import mo from './locales/mo.json';
import mn from './locales/mn.json';
import na from './locales/na.json';
import nv from './locales/nv.json';
import ng from './locales/ng.json';
import nd from './locales/nd.json';
import ne from './locales/ne.json';
import no from './locales/no.json';
import nb from './locales/nb.json';
import nn from './locales/nn.json';
import ii from './locales/ii.json';
import oc from './locales/oc.json';
import oj from './locales/oj.json';
import cu from './locales/cu.json';
import or from './locales/or.json';
import om from './locales/om.json';
import os from './locales/os.json';
import pi from './locales/pi.json';
import ps from './locales/ps.json';
import fa from './locales/fa.json';
import pl from './locales/pl.json';
import pt from './locales/pt.json';
import pa from './locales/pa.json';
import qu from './locales/qu.json';
import rm from './locales/rm.json';
import ro from './locales/ro.json';
import ru from './locales/ru.json';
import se from './locales/se.json';
import sm from './locales/sm.json';
import sg from './locales/sg.json';
import sa from './locales/sa.json';
import sr from './locales/sr.json';
import sh from './locales/sh.json';
import st from './locales/st.json';
import tn from './locales/tn.json';
import sn from './locales/sn.json';
import sd from './locales/sd.json';
import si from './locales/si.json';
import ss from './locales/ss.json';
import sk from './locales/sk.json';
import sl from './locales/sl.json';
import so from './locales/so.json';
import nr from './locales/nr.json';
import es from './locales/es.json';
import su from './locales/su.json';
import sw from './locales/sw.json';
import sv from './locales/sv.json';
import tl from './locales/tl.json';
import ty from './locales/ty.json';
import tg from './locales/tg.json';
import ta from './locales/ta.json';
import tt from './locales/tt.json';
import te from './locales/te.json';
import th from './locales/th.json';
import bo from './locales/bo.json';
import ti from './locales/ti.json';
import to from './locales/to.json';
import ts from './locales/ts.json';
import tr from './locales/tr.json';
import tk from './locales/tk.json';
import tw from './locales/tw.json';
import ug from './locales/ug.json';
import uk from './locales/uk.json';
import ur from './locales/ur.json';
import uz from './locales/uz.json';
import ve from './locales/ve.json';
import vi from './locales/vi.json';
import vo from './locales/vo.json';
import wa from './locales/wa.json';
import cy from './locales/cy.json';
import wo from './locales/wo.json';
import fy from './locales/fy.json';
import xh from './locales/xh.json';
import yi from './locales/yi.json';
import yo from './locales/yo.json';
import za from './locales/za.json';
import zu from './locales/zu.json';

const translations = {
  ab,
  aa,
  af,
  ak,
  sq,
  am,
  ar,
  an,
  hy,
  as,
  av,
  ae,
  ay,
  az,
  bm,
  ba,
  eu,
  be,
  bn,
  bh,
  bi,
  bs,
  br,
  bg,
  my,
  ca,
  ch,
  ce,
  ny,
  zh,
  'zh-Hans': zhHans,
  'zh-Hant': zhHant,
  cv,
  kw,
  co,
  cr,
  hr,
  cs,
  da,
  dv,
  nl,
  dz,
  en,
  eo,
  et,
  ee,
  fo,
  fj,
  fi,
  fr,
  ff,
  gl,
  gd,
  gv,
  ka,
  de,
  el,
  kl,
  gn,
  gu,
  ht,
  ha,
  he,
  hz,
  hi,
  ho,
  hu,
  is,
  io,
  ig,
  id,
  ia,
  ie,
  iu,
  ga,
  it,
  ja,
  jv,
  kn,
  kr,
  ks,
  kk,
  km,
  ki,
  rw,
  rn,
  ky,
  kv,
  kg,
  ko,
  ku,
  kj,
  lo,
  la,
  lv,
  li,
  ln,
  lt,
  lu,
  lg,
  lb,
  mk,
  mg,
  ms,
  ml,
  mt,
  mi,
  mr,
  mh,
  mo,
  mn,
  na,
  nv,
  ng,
  nd,
  ne,
  no,
  nb,
  nn,
  ii,
  oc,
  oj,
  cu,
  or,
  om,
  os,
  pi,
  ps,
  fa,
  pl,
  pt,
  pa,
  qu,
  rm,
  ro,
  ru,
  se,
  sm,
  sg,
  sa,
  sr,
  sh,
  st,
  tn,
  sn,
  sd,
  si,
  ss,
  sk,
  sl,
  so,
  nr,
  es,
  su,
  sw,
  sv,
  tl,
  ty,
  tg,
  ta,
  tt,
  te,
  th,
  bo,
  ti,
  to,
  ts,
  tr,
  tk,
  tw,
  ug,
  uk,
  ur,
  uz,
  ve,
  vi,
  vo,
  wa,
  cy,
  wo,
  fy,
  xh,
  yi,
  yo,
  za,
  zu
};

export default translations;
