import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Admin from './pages/Admin';
import Login from './pages/Login';
import { auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "./firebaseConfig"; // 確保已經正確引入 Firestore 的配置
import i18n from './i18n/i18n';
import { clientWrite } from "./algolia";
import { signOut } from 'firebase/auth';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const algoliaIndex = clientWrite.initIndex('Users');

  const saveToAlgolia = (user) => {
    algoliaIndex.saveObject({
      objectID: user.uid, // Algolia 需要 objectID 作為唯一標識
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      region: i18n.region,
      deviceLan: i18n.locale
    });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // 檢查 Firestore 裡是否已經有這個使用者
        const userDocRef = doc(db, "Users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if(userData.delete){
            // Alert.alert(i18n.t("此帳號已被刪除"));
            console.log("此帳號已被刪除");
            await signOut(auth);
            return ;
          }
          const updatedUserData = {
            uid: user.uid,
            email: userData.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            providerId: user.providerData[0].providerId,
            region: i18n.region,
            deviceLan: i18n.locale,
            ...userData
          };
          setUser(updatedUserData);

          if (!userData.uid) {
            await updateDoc(userDocRef, {
              ...updatedUserData,
              createAt: serverTimestamp()
            });
          }
        } else {
          const newUser = {
            uid: user.uid,
            email: user.providerData[0].email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            providerId: user.providerData[0].providerId,
            region: i18n.region,
            deviceLan: i18n.locale,
            createAt: serverTimestamp()
          };

          setUser(newUser);
          await setDoc(userDocRef, newUser);
          saveToAlgolia(user);
        }
        
        const token = await user.getIdTokenResult();
        if (token.claims.admin) {
          setUser((prevUser) => ({
            ...prevUser,
            admin: true
          }));
        } else {
          setUser((prevUser) => ({
            ...prevUser,
            admin: false
          }));
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route 
          path='/admin' 
          element={user ? (user.admin ? <Admin /> : <Navigate to='/' />) : <Navigate to='/login' />} 
        />
      </Routes>
    </Router>
  );
}

export default App;
